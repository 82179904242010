html,
body {
  color: #000;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  width: 100%;
  padding: 20px;
}

* {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
input,
label {
  font-family: "NEXTBook-Regular";
  font-weight: 300;
  margin: 0;
  padding: 0;
  letter-spacing: 0;
}

input {
  padding: 10px;
  font-family: "NEXTBook-Light";
  font-size: 20px;
  background: #fff;
  border: 1px solid #ccc;
}

h1 {
  font-family: "NEXTBook-Light";
  font-size: 24px;
  letter-spacing: 0;

  line-height: 28px;
}

p {
  font-family: "NEXTBook-Regular";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

a {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #5a5a5a;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

@media (max-width: 900px) {
  h1 {
    font-size: 20px;
  }

  p,
  a {
    font-size: 14px;
  }
}

a:hover {
  color: #e20015;
}

.noscroll { 
  overflow:hidden;
}
